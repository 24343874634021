<template>
  <div class="about">
    <h1>About this project</h1>
    <h1>About this website</h1>
    <h2>Author</h2>
    <p>International Erasmus Student Network France is an association governed by French law of July 1st 1901 located at 50 rue des Tournelles, 75003 Paris (France).</p>
    <p>This website is hosted in France by OVH SAS, 2 rue Kellermann - 59100 Roubaix, France. https://www.ovhcloud.com/</p>
    <h2>Images credits</h2>
      <ul>
        <li>
          <a href="https://www.flaticon.com/free-icons/idea" target="_blank" title="idea icons">Idea</a>,
          <a href="https://www.flaticon.com/free-icons/money" target="_blank" title="money icons">Money</a>,
          <a href="https://www.flaticon.com/free-icons/house" target="_blank" title="house icons">House</a>,
          <a href="https://www.flaticon.com/free-icons/train" target="_blank" title="train icons">Train</a>,
          and <a href="https://www.flaticon.com/free-icons/health" target="_blank" title="health icons">Health</a>
          icons created by Freepik - Flaticon
        </li>
        <li>
          <a href="https://www.flaticon.com/free-icons/visa" target="_blank" title="visa icons">Visa</a>
          and <a href="https://www.flaticon.com/free-icons/phone" target="_blank" title="phone icons">Phone</a>
          icons created by photo3idea_studio - Flaticon
        </li>
        <li>
          <a href="https://www.flaticon.com/free-icons/communication" target="_blank" title="communication icons">Communication</a>
          icons created by Vectors Market - Flaticon
        </li>
        <li>
          <a href="https://www.flaticon.com/free-icons/credit-card" target="_blank" title="credit card icons">Credit card</a>
          icons created by monkik - Flaticon
        </li>
      </ul>
  </div>
</template>

<script>
export default {
  beforeCreate() {
    this.$i18n.locale = this.$route.params.lang.toLowerCase();
  }
}
</script>

<style scoped lang="scss">
.about {
  max-width: 999px;
  margin: auto;

  li a {
    color: black;
  }
}
</style>
